
















































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import axios, { AxiosResponse } from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { ValidationProvider } from 'vee-validate'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import { mask } from 'vue-the-mask'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

import ServiceUserExamDropdown from '@/partials/forms/Associations/ServiceUserExamDropdown.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'

@Component({
  directives: { mask },
  components: {
    TrashCan16,
    ValidationProvider,
    GenericModalForm,
    GenericModalDelete,
    ServiceUserExamDropdown,
    PasswordConfirmField
  },
  filters: {
    translateExamType: function (value: 'test' | 'practice' | 'single') {
      const dict = {
        test: 'TAA',
        practice: 'TAE',
        single: 'Exercício'
      }

      return dict[value]
    }
  }
})
export default class UserAssociationExams extends Mixins(FlashMessageMixin, ValidatorConfigMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) userAssociationId!: string
  exams: Array<any> = []
  filteredData: Array<any> = []
  createModalIsOpened = false
  deleteModalIsOpened = false
  done = true
  form = {
    examScheduleServiceId: '',
    userAssociationId: this.userAssociationId,
    score: 0,
    approved: true,
    confirm: '',
    errors: {
      examScheduleServiceId: '',
      userAssociationId: '',
      score: '',
      approved: '',
      confirm: ''
    }
  }

  deleteRequestBody = {
    examScheduleServiceUserId: '',
    userAssociationId: this.userAssociationId
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`user/association/${this.userAssociationId}/my-exams`)
      .then(response => {
        this.filteredData = this.exams = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postSubmit (response: AxiosResponse) {
    this.createModalIsOpened = false
    this.done = true
    this.$emit('submitted', camelCaseKeys(response, { deep: true }))

    const flashMessage: flashMessage = {
      message: 'Nota de Prova cadastrada com sucesso.',
      type: 'success'
    }
    this.setFlashMessage(flashMessage)
  }

  onSelectExamDelete (id: string) {
    this.deleteModalIsOpened = true
    this.deleteRequestBody.examScheduleServiceUserId = id
  }

  postDeleteSubmit (response: AxiosResponse) {
    this.deleteModalIsOpened = false
    this.done = true
    this.$emit('submitted', camelCaseKeys(response, { deep: true }))

    const flashMessage: flashMessage = {
      message: 'A Nota de Prova foi removida com sucesso.',
      type: 'success'
    }
    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  resetForm () {
    this.form = {
      examScheduleServiceId: '',
      userAssociationId: this.userAssociationId,
      score: 0,
      approved: true,
      confirm: '',
      errors: {
        examScheduleServiceId: '',
        userAssociationId: '',
        score: '',
        approved: '',
        confirm: ''
      }
    }
  }

  @Watch('createModalIsOpened')
  createModalIsOpenedChanged (open: boolean) {
    if (!open) {
      this.resetForm()
    }
  }

  @Watch('done')
  doneChanged (done: boolean) {
    if (done) {
      this.fetch()
    }
  }

  tagKind (status: 'pass' | 'kill' | 'pend') {
    const dict = {
      pass: 'green',
      kill: 'red',
      pend: 'warm-gray'
    }

    return dict[status]
  }
}
