



































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import DocumentAdd16 from '@carbon/icons-vue/es/document--add/16'
import Checkmark16 from '@carbon/icons-vue/es/checkmark/16'
import Close16 from '@carbon/icons-vue/es/close/16'
import Download16 from '@carbon/icons-vue/es/download/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import axios from 'axios'
import Email24 from '@carbon/icons-vue/es/email/24'
import camelCaseKeys from 'camelcase-keys'
import { namespace } from 'vuex-class'
import { ValidationObserver } from 'vee-validate'

import PermissionsMixin from '@/mixins/permissionsMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

const sessionModule = namespace('session')

type Document = {
  id: string;
  createdAt: string;
  validatedAt: string;
  name: string;
}
type DocumentActions = 'validate' | 'invalidate' | 'trash'

@Component({
  components: {
    DocumentAdd16,
    Checkmark16,
    Close16,
    Download16,
    TrashCan16,
    Email24,
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class UserAttachments extends Mixins(PermissionsMixin) {
  @Prop({ default: 'upload' }) kind!: ServiceDocumentationKind
  @Prop({ default: undefined }) attachments!: []
  @Prop({ default: true, type: Boolean }) acceptingUploads!: boolean
  @sessionModule.Getter state!: Record<string, any>
  filteredData: Array<any> = []
  documents = []
  userAssociationId = ''

  created () {
    if (this.kind === 'upload') {
      this.userAssociationId = this.state.user.association.id
    } else {
      this.userAssociationId = this.$route.params.id
    }

    if (this.attachments) {
      this.filteredData = this.documents = this.attachments
    } else {
      this.fetchDocuments()
    }
  }

  fetchDocuments () {
    if (this.kind !== 'pending-review') {
      axios.get(`user/association/${this.userAssociationId}`)
        .then(response => {
          this.filteredData = this.documents = camelCaseKeys(response.data.data.attachments, { deep: true })
        })
    } else {
      axios.get('/association/attachment/pending')
        .then(response => {
          this.filteredData = this.documents = camelCaseKeys(response.data.data, { deep: true })
        })
        .catch(err => {
          console.log(err.response.data?.error)
          this.filteredData = this.documents = []
        })
    }
  }

  validateModalIsOpened = false
  validateDocument = {
    confirmMessage: '',
    action: 'validate'
  }

  $refs!: {
    validateObserver: any;
  }

  toggleModal (modalName: string) {
    this.$refs.validateObserver.reset()
    this.$data[modalName] = !this.$data[modalName]
  }

  reasonModalIsOpened = false
  reason = ''

  openReasonModal (reason: string) {
    this.reason = reason
    this.reasonModalIsOpened = true
  }

  selectedDocument: Document = {
    id: '',
    createdAt: '',
    validatedAt: '',
    name: ''
  }

  form: Record<string, any> = {
    notes: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  selectDocument (document: Document, action: DocumentActions) {
    const messages = {
      validate: 'Tem certeza que deseja validar o documento?',
      invalidate: 'Tem certeza que deseja invalidar o documento?',
      trash: 'Tem certeza que deseja remover o documento?'
    }

    this.selectedDocument = document

    this.validateDocument.action = action
    this.validateDocument.confirmMessage = messages[action]

    if (action === 'validate' || action === 'invalidate') {
      this.form.notes = action === 'validate' ? 'Documento em conformidade.' : 'Documento Inválido.'
    }

    this.toggleModal('validateModalIsOpened')
  }

  submit () {
    if (this.validateDocument.action !== 'trash') {
      const action = this.validateDocument.action === 'validate' ? 'accept' : 'reject'
      const route = `user/association/${this.userAssociationId}/attachment/${this.selectedDocument.id}/${action}`

      axios.patch(route, this.form)
        .then(() => {
          this.fetchDocuments()
          this.toggleModal('validateModalIsOpened')
        })
        .catch(err => this.handleSubmitError(err))
    } else {
      const route = `user/association/${this.userAssociationId}/attachment/${this.selectedDocument.id}`
      axios.delete(route, { data: this.form })
        .then(() => {
          this.fetchDocuments()
          this.toggleModal('validateModalIsOpened')
        })
        .catch(err => this.handleSubmitError(err))
    }
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  clearForm () {
    this.form.confirm = ''
    this.form.errors = {
      confirm: ''
    }

    this.$refs.validateObserver.reset()
  }

  search (searchString: string) {
    this.filteredData = this.documents.filter((document: Record<string, any>) => {
      let { title } = document
      title = title.toLowerCase()
      searchString = searchString.toLowerCase()

      if (title.includes(searchString)) {
        return document
      }
    })
  }
}
