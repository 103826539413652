










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ProgressBar extends Vue {
  @Prop({ required: true, type: Number }) percentage!: number
}
