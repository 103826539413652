



































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios, { AxiosResponse } from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { ValidationProvider } from 'vee-validate'
import { mask } from 'vue-the-mask'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  directives: { mask },
  components: {
    ValidationProvider
  }
})
export default class UserAssociationExams extends Mixins(FlashMessageMixin, ValidatorConfigMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) userAssociationId!: string
  filteredData: Array<any> = []

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`user/association/${this.userAssociationId}/my-services`)
      .then(response => {
        this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        console.info(this.filteredData)
      })
  }
}
