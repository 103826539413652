import { render, staticRenderFns } from "./Exams.vue?vue&type=template&id=e37ce318&scoped=true&"
import script from "./Exams.vue?vue&type=script&lang=ts&"
export * from "./Exams.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e37ce318",
  null
  
)

export default component.exports